// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-billing-tsx": () => import("./../../../src/pages/billing.tsx" /* webpackChunkName: "component---src-pages-billing-tsx" */),
  "component---src-pages-compute-index-tsx": () => import("./../../../src/pages/compute/index.tsx" /* webpackChunkName: "component---src-pages-compute-index-tsx" */),
  "component---src-pages-compute-networking-tsx": () => import("./../../../src/pages/compute/networking.tsx" /* webpackChunkName: "component---src-pages-compute-networking-tsx" */),
  "component---src-pages-compute-storage-tsx": () => import("./../../../src/pages/compute/storage.tsx" /* webpackChunkName: "component---src-pages-compute-storage-tsx" */),
  "component---src-pages-compute-virtualization-tsx": () => import("./../../../src/pages/compute/virtualization.tsx" /* webpackChunkName: "component---src-pages-compute-virtualization-tsx" */),
  "component---src-pages-compute-vm-detail-tsx": () => import("./../../../src/pages/compute/vm-detail.tsx" /* webpackChunkName: "component---src-pages-compute-vm-detail-tsx" */),
  "component---src-pages-iam-tsx": () => import("./../../../src/pages/iam.tsx" /* webpackChunkName: "component---src-pages-iam-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marketplace-tsx": () => import("./../../../src/pages/marketplace.tsx" /* webpackChunkName: "component---src-pages-marketplace-tsx" */),
  "component---src-pages-monitoring-alarms-tsx": () => import("./../../../src/pages/monitoring/alarms.tsx" /* webpackChunkName: "component---src-pages-monitoring-alarms-tsx" */),
  "component---src-pages-monitoring-index-tsx": () => import("./../../../src/pages/monitoring/index.tsx" /* webpackChunkName: "component---src-pages-monitoring-index-tsx" */)
}

