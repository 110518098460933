/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "react-toastify/dist/ReactToastify.css"

import CssBaseline from "@material-ui/core/CssBaseline"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import { ReactKeycloakProvider } from "@react-keycloak/web"
import Keycloak from "keycloak-js"
import React from "react"
import { Helmet } from "react-helmet"
import { I18nextProvider } from "react-i18next"

import Backdrop from '../../src/components/backdrop'
import Toast from "../../src/components/toast"
import i18next from "../../src/i18n"
import { TransitionProvider } from "../../src/providers/TransitionProvider"
import theme from "../../src/themes/theme-light"

const keycloak = typeof window !== 'undefined' && new Keycloak({
  realm: process.env.GATSBY_KEYCLOAK_REALM,
  url: process.env.GATSBY_KEYCLOAK_AUTH_URL,
  clientId: process.env.GATSBY_KEYCLOAK_AUTH_CLIENT_ID,
});

export const onClientEntry = () => {
  window.addEventListener('load', () => {
    document.body.className = document.body.className.replace(/\bno-js\b/, '')
  })
};

export default ({ children }) => (
  <>
    <Helmet>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
        rel="preconnect"
      />
    </Helmet>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TransitionProvider>
        <I18nextProvider i18n={i18next}>
          { keycloak &&
            <ReactKeycloakProvider
              authClient={keycloak}
              LoadingComponent={<Backdrop />}
            >
              {children}
            </ReactKeycloakProvider>
          }
        </I18nextProvider>
      </TransitionProvider>
      <Toast />
    </ThemeProvider>
  </>
);
